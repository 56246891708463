.expenses-list {
  list-style: none;
  padding: 0;
}

.expenses-list__fallback {
  color: white;
  font-size: 32px;
  text-align: center;
}
